import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

export const INTERNAL_AUTH_CONFIG_ID = 'auth-config-internal';

export const fusionAuthConfig: OpenIdConfiguration = {
  configId: INTERNAL_AUTH_CONFIG_ID,
  authority: environment.fusionAuth.authority,
  clientId: environment.fusionAuth.clientId,
  redirectUrl: `${window.location.origin}/auth/oauth-redirect`,
  responseType: 'code',
  scope: 'openid offline_access',
  silentRenew: true,
  useRefreshToken: true,
  renewTimeBeforeTokenExpiresInSeconds: 21600,
  triggerAuthorizationResultEvent: true,
  triggerRefreshWhenIdTokenExpired: false,
  disableIatOffsetValidation: true,
  logLevel: LogLevel.None,
};
